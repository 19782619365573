import { cn } from "@/utils/tailwind";
import { Accordion, AccordionItem } from "@nextui-org/react";
import React from "react";

const MenuItemSpan = ({ menu, onClick, className }) => {
  const { name, items } = menu;
  return (
    <p
      role="button"
      className={cn(
        `font-founders_semibold text-[24px] uppercase leading-7`,
        !items?.length && "hover:text-[#FF5C0A]",
        className,
      )}
      onClick={onClick}
    >
      {name}
    </p>
  );
};

export default function MobileNavMenu({ menu, onClickNavMenu }) {
  const { items } = menu;

  if (!items?.length) {
    return (
      <MenuItemSpan menu={menu} onClick={onClickNavMenu.bind(null, menu)} />
    );
  }

  return (
    <div className="-mx-2">
      <Accordion selectionMode="multiple" variant="light">
        <AccordionItem
          aria-label="Anchor"
          isCompact
          title={
            <MenuItemSpan
              menu={menu}
              onClick={onClickNavMenu.bind(null, menu)}
            />
          }
          indicator={({ isOpen }) => {
            return (
              <div className="relative flex">
                {isOpen ? (
                  <span
                    className={`material-symbols-outlined material-weight-300 rotate-[90deg] !text-[32px] !text-black`}
                  >
                    remove
                  </span>
                ) : (
                  <span
                    className={`material-symbols-outlined material-weight-300 !text-[32px] !text-black`}
                  >
                    add
                  </span>
                )}
              </div>
            );
          }}
          classNames={{
            heading: "py-0 px-0",
            content: "py-0 pl-6",
            trigger: "px-0 py-0",
          }}
        >
          <div className="flex flex-col space-y-3 pt-2">
            {items.map((item, index) => {
              return (
                <p
                  role="button"
                  className="font-founders_semibold text-[18px] uppercase leading-6 hover:text-[#FF5C0A]"
                  key={index}
                  onClick={onClickNavMenu.bind(null, item)}
                >
                  {item.name}
                </p>
              );
            })}
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
