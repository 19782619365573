import { useMemo, useState } from "react";

export default function DesktopNavMenu({ menu, onClickNavMenu }) {
  const [showMenu, setShowMenu] = useState(false);

  const isMenuHasItems = !!menu?.items?.length;

  const menuWithItemProps = useMemo(
    () =>
      isMenuHasItems
        ? {
            onMouseEnter: () => setShowMenu(true),
            onMouseLeave: () => setShowMenu(false),
          }
        : {},
    [isMenuHasItems],
  );

  return (
    <div
      className="relative"
      onClick={onClickNavMenu.bind(null, menu)}
      {...menuWithItemProps}
    >
      <div key={menu.name} className={`flex cursor-pointer items-center px-4`}>
        <span>{menu.name}</span>
        {/* {isMenuHasItems && (
          <>
            <span className="material-symbols-outlined !text-[20px] text-[--color]">
              {showMenu ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </span>
          </>
        )} */}
      </div>
      {showMenu && isMenuHasItems && (
        <div className="absolute -left-4 top-full w-[160px]">
          <div className="mt-4 w-full rounded-[8px] border-1 border-byteBlack-1000 bg-white p-[6px] shadow-[0px_8px_24px_0px_#0000000D]">
            {menu.items.map((menuItem, index) => {
              return (
                <p
                  key={index}
                  className="cursor-pointer p-[6px] font-founders_semibold text-[16px] leading-5 text-byteBlack-1000"
                  onClick={onClickNavMenu.bind(null, menuItem)}
                >
                  {menuItem.name}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
