import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import useResizeCallback from "@/hooks/useResizeCallback";

const getConfigWidth = (screenWidth, desktopSize) => {
  return {
    isDesktopWidth: screenWidth !== 0 && screenWidth >= desktopSize,
    isMobileWidth: screenWidth !== 0 && screenWidth < desktopSize,
  };
};
//Default context value for autocomplete
const ScreenContext = createContext({
  screenWidth: 0,
  isMobileWidth: false,
  isDesktopWidth: false,
  getCurrentScreenWidth: () => ({ isMobileWidth: true, isDesktopWidth: false }),
});

export const ScreenContextProvider = ({
  children,
  desktopSize = 1024,
  initialScreenWidth = 0,
  isResponsive = true,
}) => {
  const [screenWidth, setScreenWidth] = useState(initialScreenWidth);

  const { isDesktopWidth, isMobileWidth } = getConfigWidth(
    screenWidth,
    desktopSize,
  );

  const getCurrentScreenWidth = useCallback(() => {
    const innerWidth = window.innerWidth;
    setScreenWidth(innerWidth);
    return getConfigWidth(innerWidth, desktopSize);
  }, [desktopSize]);

  const value = useMemo(
    () => ({
      screenWidth,
      isMobileWidth,
      isDesktopWidth,
      getCurrentScreenWidth,
    }),
    [screenWidth, isMobileWidth, isDesktopWidth, getCurrentScreenWidth],
  );

  const callbackFunction = useCallback(({ width }) => {
    setScreenWidth(width);
  }, []);

  useResizeCallback(callbackFunction, isResponsive ? 100 : 0);

  return (
    <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider>
  );
};

export const useScreenContext = () => useContext(ScreenContext);
