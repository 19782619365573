import { useEffect } from "react";

function useResizeCallback(callback, debounceTime = 0) {
  useEffect(() => {
    let timeoutId;

    const triggerCallback = () => {
      const newSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      callback(newSize);
    };

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(triggerCallback, debounceTime);
    };

    // Call the callback immediately when the component mounts
    triggerCallback();

    if(!debounceTime) return;

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeoutId);
    };
  }, [callback, debounceTime]);
}

export default useResizeCallback;
