import { APP_IOS_LINK } from "@/utils/app/link";

export const MENU_ITEMS_MOBILE = [
  {
    target: "/pod",
    name: "PRE ORDER BYTES",
  },
  {
    target: "https://bythen-ai.gitbook.io/bythen-ai",
    name: "WHITEPAPER",
  },
  {
    target: "/mycollection",
    name: "MY COLLECTION",
  },
  {
    target: "/studio",
    name: "STUDIO",
  },
];

export const getChatButtonName = (isShowChatRoom) => {
  return isShowChatRoom ? "CHATROOM" : "TRY DEMO";
};

export const NAV_MENU = [
  {
    target: null,
    name: "DOWNLOADS",
    items: [
      {
        name: "FOR IOS",
        target: APP_IOS_LINK,
      },
    ],
  },
  {
    target: null,
    name: "DOCS",
    items: [
      {
        name: "WHITEPAPER",
        target: "https://bythen-ai.gitbook.io/bythen-ai",
      },
      {
        name: "USER GUIDE",
        target: "https://bythen-ai.gitbook.io/bythen-user-guide",
      },
    ],
  },
];
