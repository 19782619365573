import { signal } from "@preact/signals-react";

export const preconnectAction = signal(null);

export function resetPreconnectAction() {
    preconnectAction.value = null;
}

export function setPreconnectAction(data) {
    preconnectAction.value = data;
}