import { motion } from "framer-motion";
import { ENVIRONMENT } from "@/utils/environment";
import Image from "next/image";

const BythenNavLogo = ({ isBlack }) => {
  // const [isScrolling, setScrolling] = useState(false);
  // const { scrollY } = useScroll();

  // useMotionValueEvent(scrollY, "change", (latest) => {
  //   if (latest > 100) setScrolling(true);
  //   else setScrolling(false);
  // });

  return (
    <div className={`min-w-auto min-w-[80px] md:min-w-[145px]`}>
      <motion.div className="overflow-hidden">
        <motion.div
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          transition={{ duration: 0.3, type: "tween" }}
          className="relative h-[20px] w-[80px] md:h-[28px] md:w-[106px]"
        >
          <a href="/">
            <Image
              unoptimized
              src={
                isBlack
                  ? `${ENVIRONMENT.ASSETS_URL}/landing/logo_black.svg`
                  : `${ENVIRONMENT.ASSETS_URL}/landing/logo.svg`
              }
              fill
              alt="bythen logo"
            />
          </a>
        </motion.div>
      </motion.div>

      {/* {isScrolling && (
        <motion.div className="overflow-hidden">
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3, type: "tween" }}
          >
            <a href="/">
              <Image
                unoptimized
                src={
                  isBlack
                    ? `${ENVIRONMENT.ASSETS_URL}/landing/logo_small_black.svg`
                    : `${ENVIRONMENT.ASSETS_URL}/landing/logo_small_white.svg`
                }
                width={100}
                height={26}
                alt="bythen logo"
              />
            </a>
          </motion.div>
        </motion.div>
      )} */}
    </div>
  );
};

export default BythenNavLogo;
