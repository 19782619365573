import { useCallback } from "react";
import BythenConnectWallet from "@/components/Header/BythenConnectWallet";

import BythenNavLogo from "./components/BythenNavLogo";
import { modalDialog } from "../layout";
import { ENVIRONMENT } from "@/utils/environment";
import { useAuthContext } from "@/contexts/auth/context";
import ButtonNav from "./components/ButtonNav";
import { cn } from "@/utils/tailwind";
import { getChatButtonName, NAV_MENU } from "./const";
import MobileCollapseMenu from "./components/MobileCollapseMenu";
import { ScreenContextProvider } from "@/pages/mycollection/contexts/ScreenContext";
import DesktopNavMenu from "./components/DesktopNavMenu";
import { APP_IOS_LINK } from "@/utils/app/link";
import NavbarDialog from "./components/NavbarDialog";

// TODO: Add logic byte in header
// import { useQuery } from "@tanstack/react-query";
// import { getByteDetail } from "@/service/dojo/bytes";

const HEADER_THEME = {
  white: {
    "--color": "#FFFFFF",
    "--color-10": "#FFFFFF1A",
  },
  black: {
    "--color": "#0E100F",
    "--color-10": "#0E100F1A",
  },
};

export default function Header({ isBlack, bgColor, withBorder }) {
  const { isByteExist, isAuthorized } = useAuthContext();

  const handleClickNavMenu = useCallback((menu) => {
    const { target } = menu || {};
    if (target) {
      window.open(target, "_blank");
    }
  }, []);

  const handleClickStudio = () => {
    window.open(APP_IOS_LINK, "_blank");
  };

  const handleClickMyCollection = () => {
    window.open(`${ENVIRONMENT.BASE_URL}/mycollection`, "_blank");
  };

  const isShowChatRoom = isAuthorized && isByteExist;

  const handleClickChat = useCallback(() => {
    window.open(
      `${ENVIRONMENT.BASE_URL}/${isShowChatRoom ? "chat" : "demo"}`,
      "_blank",
    );
  }, [isShowChatRoom]);

  const handleClickBurgerMenu = () => {
    modalDialog.value = {
      show: true,
      placement: "bottom",
      hideCloseButton: true,
      baseClass:
        "w-full max-w-full sm:w-full sm:max-w-full sm:mx-0 md:max-w-full lg:max-w-full border-none no-scrollbar max-h-[100vh] md:max-h-[100vh] rounded-t-[12px] md:rounded-t-[12px] md:rounded-t-[16px] md:rounded-b-none md:mx-0",
      bodyClass:
        "rounded-t-[12px] md:rounded-t-[12px] md:rounded-b-0 bg-white border-none px-4 py-8",

      body: (
        <ScreenContextProvider>
          <MobileCollapseMenu
            onClickChat={handleClickChat}
            onClickMyCollection={handleClickMyCollection}
            onClickNavMenu={handleClickNavMenu}
            onClickStudio={handleClickStudio}
          />
        </ScreenContextProvider>
      ),
    };
  };

  return (
    <>
      <header
        style={HEADER_THEME[isBlack ? "black" : "white"]}
        className={cn(
          `fixed top-0 z-[49] w-full text-[--color] [&_.menu-item:after]:bg-[--color] [&_.menu-item:before]:bg-[--color]`,
          bgColor,
          withBorder && "border-b-1 md:pb-8",
          "px-[12px] py-[20px] md:px-[24px] md:py-[20px]",
        )}
      >
        <div className="m-auto flex w-full max-w-[1920px] items-center justify-between space-x-4 transition-all">
          <div className="flex items-center space-x-4">
            <BythenNavLogo isBlack={isBlack} />

            {/* Desktop nav menu */}
            <div className="hidden font-founders_semibold text-base lg:flex">
              {NAV_MENU.map((menu, index) => (
                <DesktopNavMenu
                  key={index}
                  menu={menu}
                  onClickNavMenu={handleClickNavMenu}
                />
              ))}
            </div>
          </div>

          <div className="flex items-center">
            {/* Studio Desktop */}
            {isAuthorized && (
              <NavbarDialog
                dialogContainerClass="w-[232px] -left-[65px]"
                dialogClass="p-4"
                dialog={
                  <>
                    {/* <div className="absolute -top-3 left-[calc(50%-10px)] h-3 w-3 border-b-1 border-b-white bg-red-500"></div> */}
                    <div className="absolute -top-4 left-[calc(50%-10px)] h-4 w-4 bg-byteBlack-1000 [clip-path:url(#clip-pointer)]"></div>
                    <div className="absolute -top-[14.5px] left-[calc(50%-10px)] h-4 w-4 bg-white [clip-path:url(#clip-pointer)]"></div>
                    <p className="text-byteBlack-1000">
                      Studio feature is currently only available on iOS.{" "}
                      <u className="cursor-pointer" onClick={handleClickStudio}>
                        Download now
                      </u>
                    </p>
                  </>
                }
              >
                <ButtonNav isBlack={false} className={"hidden lg:flex"}>
                  <span className="material-symbols-outlined !-mb-[2px] !text-[16px]">
                    videocam
                  </span>
                  <span>STUDIO</span>
                </ButtonNav>
              </NavbarDialog>
            )}

            {/* Chat button Desktop */}
            <ButtonNav
              isBlack={isAuthorized && isBlack}
              onClick={handleClickChat}
              className={!isAuthorized ? "hidden lg:flex" : "ml-3 flex"}
            >
              {isShowChatRoom && (
                <span className="material-symbols-outlined !-mb-[2px] !text-[16px]">
                  chat_bubble
                </span>
              )}
              <span>{getChatButtonName(isShowChatRoom)}</span>
            </ButtonNav>

            {/* Separator */}
            {isAuthorized && (
              <div className="ml-3 hidden h-9 border-r-1 text-[--color-10] lg:block" />
            )}

            {/* Desktop connect and disconnect */}
            <BythenConnectWallet
              isBlack={isBlack}
              onClickMyCollection={handleClickMyCollection}
            />

            {/* Burger menu mobile */}
            <button
              className="ml-1 flex items-center p-1 lg:hidden"
              onClick={handleClickBurgerMenu}
            >
              <span className="material-symbols-outlined !text-[24px]">
                menu
              </span>
            </button>
          </div>
        </div>
      </header>
    </>
  );
}
