import BythenButton from "@/components/Button";
import { cn } from "@/utils/tailwind";
import { useCallback } from "react";

export default function ButtonNav({
  children,
  isBlack,
  className,
  onClick = () => {},
  isLoading,
  isDisabled,
}) {
  const isDisabledButton = !!isDisabled || isLoading;

  const handleClick = useCallback(
    (e) => {
      if (isDisabledButton) return;
      onClick(e);
    },
    [isDisabledButton, onClick],
  );

  return (
    <BythenButton
      type={isBlack ? "black" : "white"}
      size="sm"
      onClickButton={handleClick}
      classNames={cn("rounded-[100px] px-[10px]", className)}
    >
      <div className="mx-auto flex items-center space-x-2">{children}</div>
    </BythenButton>
  );
}
