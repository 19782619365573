import { cn } from "@/utils/tailwind";
import { useState } from "react";

export default function NavbarDialog({
  dialog,
  children,
  dialogContainerClass,
  dialogClass,
}) {
  const [showDialog, setShowDialog] = useState(false);

  const handleMouseEnter = () => {
    setShowDialog(true);
  };

  const handleMouseLeave = () => {
    setShowDialog(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg width="0" height="0">
        <defs>
          <clipPath id="clip-pointer" clipPathUnits="objectBoundingBox">
            <polygon points="0.5 0.5, 0 1, 1 1" />
          </clipPath>
        </defs>
      </svg>

      {children}

      {showDialog && (
        <div
          className={cn(
            `absolute -left-4 top-full w-[160px]`,
            dialogContainerClass,
          )}
        >
          <div
            className={cn(
              "relative mt-4 w-full rounded-[8px] border-1 border-byteBlack-1000 bg-white p-[6px] text-[14px] leading-5 tracking-[0.02px] shadow-[0px_8px_24px_0px_#0000000D]",
              dialogClass,
            )}
          >
            {dialog}
          </div>
        </div>
      )}
    </div>
  );
}
